import { useEffect, useState } from 'react';
import './App.css';
import contract from './contracts/NFTCollectible.json';
import { ethers } from 'ethers';
import axios from 'axios';

const abi = contract.abi;

function App() {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [contractAddresses, setContractAddresses] = useState([]);
  const [tx, setTx] = useState(0);
  const [lastTxDatetime, setLastTxDatetime] = useState("");
  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };
  async function getZksEra(address) {
    if (address === null) {
      return
    }
    try {
        let url = "https://zksync2-mainnet-explorer.zksync.io/address/" + address;
        const response = await axios.get(url);
        let tx2, balance2, usdcBalance;
        if ("0x0000000000000000000000000000000000000000" in response.data.info.balances) {
            balance2 = (parseInt(response.data.info.balances["0x0000000000000000000000000000000000000000"]
                .balance, 16) / 10 ** 18).toFixed(3)
        } else {
            balance2 = 0;
        }
        if ("0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4" in response.data.info.balances) {
            usdcBalance = (parseInt(response.data.info.balances["0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4"]
                .balance, 16) / 10 ** 6).toFixed(3)
        } else {
            usdcBalance = 0;
        }
        tx2 = response.data.info.sealedNonce;
        return tx2;
    } catch (error) {
        console.error(error);
        return "Error";
    }
  }
  async function getZkSyncLastTX(address) {
    if (address === null) {
      return
    }
    const url = "https://zksync2-mainnet-explorer.zksync.io/transactions?limit=5&direction=older&accountAddress=" + address;
    try {
        const response = await axios.get(url);
        if (response.data.total === 0) {
            return {"zkSyncLastTx": "无交易"}
        } else {
            const lastTxDatetime = response.data.list[0].receivedAt;
            const date = new Date(lastTxDatetime);
            const offset = 8;
            const utc8Date = new Date(date.getTime() + offset * 3600 * 1000);
            const now = new Date();
            const utc8Now = new Date(now.getTime() + offset * 3600 * 1000);
            const diff = utc8Now - utc8Date;
            const diffInHours = Math.floor(diff / (1000 * 60 * 60));
            const diffInDays = Math.floor(diffInHours / 24);
            if (diffInDays > 0) {
                return `${diffInDays} 天前`;
            } else if (diffInHours > 0) {
                return `${diffInHours} 小时前`;
            } else {
                return "刚刚";
            }
        }
    } catch (error) {
        console.error(error);
        return "Error";
    }
  }
  function saveContractAddress() {
    const contractAddressInput = document.getElementById('contractAddress');
    const contractAddressText = contractAddressInput.value.trim();
  
    if (!contractAddressText) {
      alert('请输入合约地址');
      return;
    }
  
    const addresses = contractAddressText.split('\n');
  
    if (addresses.length === 0) {
      alert('请输入合约地址');
      return;
    }
  
    const invalidAddresses = addresses.filter(address => !ethers.utils.isAddress(address));
  
    if (invalidAddresses.length > 0) {
      alert('输入的合约地址不正确，请检查后重新输入');
      return;
    }
  
    const newContractAddresses = [...contractAddresses, ...addresses];
    // 去重
    const uniqueContractAddresses = [...new Set(newContractAddresses)];
    setContractAddresses(uniqueContractAddresses);
    localStorage.setItem('contractAddresses', JSON.stringify(newContractAddresses));
  
    contractAddressInput.value = '';
  }
  

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      return;
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });
    // 获取链id
    const chainId = await ethereum.request({ method: 'eth_chainId' });
    console.log("Connected to chain " + chainId);
    if (chainId !== '0x144') {
      alert('请切换到zkSync链');
      return;
    }

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account: ", account);
      setCurrentAccount(account);
    } else {
      console.log("No authorized account found");
    }
  }

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      alert("Please install Metamask!");
      return;
    }
    const chainId = await ethereum.request({ method: 'eth_chainId' });
    if (chainId !== '0x144') {
      alert('请切换到zkSync链');
      return;
    }
    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log("Found an account! Address: ", accounts[0]);
      setCurrentAccount(accounts[0]);
      const tx_count = await getZksEra(accounts[0]);
      setTx(tx_count);
      const lastTxDatetime = await getZkSyncLastTX(accounts[0]);
      setLastTxDatetime(lastTxDatetime);

    } catch (err) {
      console.log(err);
    }
  }

  const mintNftHandler = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        console.log("Ethereum object does not exist");
        return;
      }
      const chainId = await ethereum.request({ method: 'eth_chainId' });
      if (chainId !== '0x144') {
        alert('请切换到zkSync链');
        return;
      }
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      if (!selectedValue) {
        setSelectedValue(contractAddresses[0]);
        return;
      }
      const nftContract = new ethers.Contract(selectedValue, abi, signer);

      console.log("Initialize payment");
      let nftTxn = await nftContract.execute();

      console.log("Mining... please wait");
      await nftTxn.wait();

      console.log(`Mined, see transaction: https://explorer.zksync.io/tx/${nftTxn.hash}`);
      alert("success");
      const fetchData = async () => {
        try {
          const tx_count = await getZksEra(currentAccount);
          setTx(tx_count);
          const lastTxDatetime = await getZkSyncLastTX(currentAccount);
          setLastTxDatetime(lastTxDatetime);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    checkWalletIsConnected();
    const savedContractAddresses = JSON.parse(localStorage.getItem('contractAddresses')) || [];
    const uniqueContractAddresses = [...new Set(savedContractAddresses)];
    setContractAddresses(uniqueContractAddresses);
    selectedValue || setSelectedValue(uniqueContractAddresses[0]);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tx_count = await getZksEra(currentAccount);
        setTx(tx_count);
        const lastTxDatetime = await getZkSyncLastTX(currentAccount);
        setLastTxDatetime(lastTxDatetime);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [currentAccount]);
  window.ethereum.on('accountsChanged', function (accounts) {
    // 用户账户发生变化时的处理逻辑
    // console.log('切换地址:', accounts);
    // 执行更新逻辑
    setCurrentAccount(accounts[0]);
  });

  const accountInfo = () => {
    if (currentAccount) {
      return (
        <div className='account-info'>
          <div className='account-info-item'>
            <span className='account-info-item-label'>Tx:</span>
            <span className='account-info-item-value'>{tx}</span>
          </div>
          <div className='account-info-item'>
            <span className='account-info-item-label'>Last Tx:</span>
            <span className='account-info-item-value'>{lastTxDatetime}</span>
          </div>
        </div>
      );
    }
  }
  const renderWalletButton = () => {
    if (currentAccount) {
      return (
        <button onClick={connectWalletHandler} className='cta-button connected-wallet-button'>
          Connected
        </button>
      );
    } else {
      return (
        <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
          Connect Wallet
        </button>
      );
    }
  }

  const renderContractAddressForm = () => {
    return (
      <div>
        <label htmlFor="contractAddress">导入合约地址：</label>
        <textarea id="contractAddress" placeholder="输入合约地址 一行一个" rows="3"></textarea>
        <button onClick={saveContractAddress} className='cta-button save-contract-addr-button'>
          Save
        </button>
      </div>
    );
  }

  const renderContractList = () => {
    return (
      <div>
        <h2>选择交互合约：</h2>
        <select id="contractList" value={selectedValue} onChange={handleDropdownChange}>
          {contractAddresses.map(contractAddress => (
            <option key={contractAddress} value={contractAddress}>{contractAddress}</option>
          ))}
        </select>
      </div>
    );
  }

  const renderMintNftButton = () => {
    return (
      <div>
        <button onClick={mintNftHandler} className='cta-button mint-nft-button'>
          Execute
        </button>
      </div>
    );
  }

  return (
    <div className='main-app'>
      <header>
        <h1>ZkSync Era tx++</h1>
        <div>{accountInfo()}</div>
        <div>{renderWalletButton()}</div>
      </header>

      <div id="walletStatus">
        <p id="walletAddress"></p>
        <p id="walletNetwork"></p>
      </div>

      <div>{renderContractAddressForm()}</div>
      <div>{renderContractList()}</div>
      <div>{renderMintNftButton()}</div>
    </div>
  )
}

export default App;
